import mpLogo from "../../assets/img/logo.svg";
import song from "../../assets/img/song-icon.svg";
import doubt from "../../assets/img/doubt-icon.svg";
import email from "../../assets/img/email-icon.svg";
import mpInfo from "../../assets/img/mp-info.svg";
import mpInfoBlack from "../../assets/img/mp-info-black.svg";

import facebook from "../../assets/img/facebook.svg";
import instagram from "../../assets/img/instagram.svg";
import youtube from "../../assets/img/youtube.svg";

import {
  Container,
  PrimaryButton,
  SecondaryOutlineButton,
  SecondaryButton,
} from "./styles";
import useWindowDimensions from "../../utils/useDimensions";
import { getMobileOperatingSystem } from "../../utils/useUserOS";
import { useIntercom } from "react-use-intercom";

type Props = {
  isIntercomOpen: boolean;
};

export default function Main({ isIntercomOpen }: Props) {
  const { show } = useIntercom();
  const { width } = useWindowDimensions();
  const userOS = getMobileOperatingSystem();

  function redirectToDownloadApp(e: any) {
    e.preventDefault();

    const userPossibleOS = {
      iOS: "https://apps.apple.com/br/app/musicplayce/id1422159020",
      Android:
        "https://play.google.com/store/apps/details?id=com.musicplayce.app",
      "Windows Phone": "https://musicplayce.com/baixar/",
      unknown: "https://musicplayce.com/baixar/",
    };

    window.open(userPossibleOS[userOS], "_blank");
  }

  return (
    <Container>
      <div id="first-section">
        <header id="header-mp">
          <div id="align-space">
            <img
              src={mpLogo}
              id="logo"
              alt="mp-logo"
              onClick={() => window.open("http://musicplayce.com/", "blank")}
            />
            <div id="social-networks-container">
              <span id="follow-on-social-media">Siga nas redes</span>
              <a
                href="https://www.instagram.com/musicplayce/?hl=pt-br"
                target="blank"
              >
                <img src={instagram} id="instagram" alt="mp-instagram" />
              </a>
              <a href="https://www.facebook.com/musicplayce/" target="blank">
                <img src={facebook} id="facebook" alt="mp-facebook" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCGtAUhQV5oWURcdy8hyfO1Q"
                target="blank"
              >
                <img src={youtube} id="youtube" alt="mp-youtube" />
              </a>
            </div>
          </div>
        </header>
        <div id="fs-content">
          <div id="left-side-container">
            <div id="align-musicicon-songwriter">
              <img src={song} id="song-icon" alt="song-icon" />

              <span id="composer-header">Compositor,</span>
            </div>

            <div id="left-side-container-texts">
              <span id="composer-subheader">
                tá difícil fazer sua música chegar aos artistas?
              </span>

              <span id="composer-text">
                Assista agora mesmo ao vídeo para entender como podemos te
                ajudar a se destacar no mundo da música!
              </span>
            </div>
          </div>

          <iframe
            title="video"
            src="https://www.youtube.com/embed/F6NMLC9fh-0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div id="steps-section">
          <div className="steps">
            <div className="steps-col">
              <div className="step">
                <div className="step-content">
                  <span className="step-title">
                    Baixe nosso App e cadastre-se
                  </span>
                  <span className="step-description">
                    Entre para o MusicPlayce! Chegou a sua vez de brilhar. Baixe
                    o app pelo Googleplay ou AppleStore e crie a sua conta!
                  </span>
                </div>
              </div>
              <div className="step">
                <div className="step-content">
                  <span className="step-title">
                    Faça upload das suas composições
                  </span>
                  <span className="step-description">
                    Se você confia no potencial da sua obra, suba na plataforma
                    com todas as informações de gênero, tema, compositores e
                    hashtags relacionadas.
                  </span>
                </div>
              </div>
            </div>
            <div className="steps-col">
              <div className="step">
                <div className="step-content">
                  <span className="step-title">
                    Engaje seu público nas redes sociais
                  </span>
                  <span className="step-description">
                    Compartilhe suas composições nas redes sociais e descubra
                    com quem ela combina. Deixe os fãs ajudarem sua música falar
                    mais alto.
                  </span>
                </div>
              </div>
              <div className="step">
                <div className="step-content">
                  <span className="step-title">
                    Gere dados e convença os artistas
                  </span>
                  <span className="step-description">
                    Menos: "- ouve essa com carinho" e Mais: "- Essa composição
                    tem 80% de validação pra você. Gostaria de ouvir?"
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="loose-button">
          <PrimaryButton onClick={(e) => redirectToDownloadApp(e)}>
            <span>Entre para o MusicPlayce!</span>
          </PrimaryButton>
        </div>
      </div>

      <div id="help-section">
        <div className="cards negative">
          <img src={doubt} alt="doubt" />
          <span className="title">Com dúvidas?</span>
          <span className="subtitle negative-sub">
            Veja as perguntas e respostas mais frequentes em nossa Central de
            Ajuda Intercom
          </span>
          <SecondaryOutlineButton
            onClick={() =>
              window.open("https://intercom.help/musicplayce/pt-BR", "_blank")
            }
            style={{ marginTop: "auto" }}
          >
            <span>Acessar Central de Ajuda</span>
          </SecondaryOutlineButton>
        </div>
        <div className="cards normal">
          <img src={email} alt="doubt" />
          <span className="title">Envie sua dúvida</span>
          <span className="subtitle normal-sub">
            Não encontrou a resposta que procurava? Acesse nossa Central de
            Ajuda Intercom e envie-nos a sua dúvida que em breve nós a
            responderemos ;)
          </span>
          <SecondaryButton
            onClick={() => {
              if (isIntercomOpen) return;
              show();
            }}
            style={{ marginTop: "auto" }}
          >
            <span>Enviar minha dúvida</span>
          </SecondaryButton>
        </div>
      </div>
      <div id="footer">
        <div id="info">
          {width > 767 ? (
            <img src={mpInfo} alt="mp-info" />
          ) : (
            <img src={mpInfoBlack} alt="mp-info" />
          )}
        </div>
      </div>
    </Container>
  );
}
