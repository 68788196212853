import React, { useState } from "react";
import { IntercomProvider } from "react-use-intercom";
import Main from "./pages/main/Main";

const App: React.FC = () => {
  const [intercomIsOpen, setIntercomIsOpen] = useState(false);

  return (
    <IntercomProvider
      appId="byo6j7o1"
      autoBoot
      onHide={() => setIntercomIsOpen(false)}
      onShow={() => setIntercomIsOpen(true)}
    >
      <Main isIntercomOpen={intercomIsOpen} />
    </IntercomProvider>
  );
};

export default App;
