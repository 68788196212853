import styled from "styled-components";
import bgImage from "../../assets/img/bg.svg";

export const Container = styled.div`
  width: 100%;
  height: 100vh;

  #first-section {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url(${bgImage}),
      linear-gradient(180deg, rgb(32 32 32) 100%, #202020 100%);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    #header-mp {
      width: 100%;
      background-color: #202020;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;

      #align-space {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 90%;
        max-width: 1100px;
      }

      #logo {
        cursor: pointer;
        width: 60%;
        max-width: 200px;
      }

      #social-networks-container {
        display: flex;

        #follow-on-social-media {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          color: #ffffff;
          margin-right: 16px;
        }

        #facebook {
          margin: 0 16px 0 16px;
        }
      }
    }

    #fs-content {
      width: 90%;
      max-width: 1100px;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      #left-side-container {
        display: flex;
        flex-direction: column;
        margin-right: 67px;

        #align-musicicon-songwriter {
          display: flex;
          align-items: center;
        }

        #left-side-container-texts {
          display: flex;
          flex-direction: column;
          margin-left: 60px;
        }
      }

      #logo {
        width: 400px;
      }

      #song-icon {
        width: 40%;
        max-width: 42px;
        margin-right: 15px;
      }

      #composer-header {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 72px;
        text-align: center;
        color: #e4e5e6;
      }

      #composer-subheader {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        color: #e4e5e6;
        margin: 16px 0;
      }

      #composer-text {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        color: #e4e5e6;
      }

      iframe {
        margin-top: 60px;
        margin-bottom: 20px;
        width: 1100px !important;
        height: 315px !important;
        border: none !important;
      }
    }
  }

  #steps-section {
    width: 100%;
    height: auto;
    padding: 10px 0 50px 0;

    .steps {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .steps-col {
        display: flex;
        flex-direction: row;

        .step {
          width: 240px;
          height: fit-content;
          display: flex;
          flex-direction: row;
          margin: 0 23px;

          .number {
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            line-height: 28px;
            color: #ffffff;
            margin-right: 10px;
          }

          .step-content {
            display: flex;
            flex-direction: column;

            .step-title {
              font-family: Inter;
              font-style: normal;
              font-weight: 600;
              font-size: 24px;
              line-height: 26px;

              color: #0d65ff;
            }
            .step-description {
              font-family: Inter;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 26px;
              color: #ffffff;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }

  #loose-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 80px;
  }

  #help-section {
    width: 100%;
    height: 337px;
    background: #2e2e2e;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .cards {
      height: 250px;
      width: fit-content;
      border-radius: 6px;
      padding: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 133px 10px 0 10px;

      img {
        width: 40px;
        height: 40px;
        margin-bottom: 10px;
      }

      .title {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 38px;
        text-align: center;
      }

      .subtitle {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        margin-bottom: 30px;
        width: 390px;
      }
    }

    .negative {
      background: #262626;
      color: #ffffff;

      .negative-sub {
        color: #8f8f8f;
      }
    }
    .normal {
      background: #f5f5f5;
      color: #262626;

      .normal-sub {
        color: #444242;
      }
    }
  }

  #footer {
    width: 100%;
    height: 337px;
    background: #202020;
    margin-top: -33px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    #info {
      display: flex;
      justify-content: center;
      align-items: flex-end;

      img {
        width: 140px;
        position: relative;
        bottom: 0;
        margin-bottom: 60px;
      }
    }
  }

  /* ---------------- MEDIA QUERIES ---------------- */

  @media screen and (max-width: 1160px) {
    #left-side-container {
      display: flex;
      flex-direction: column;

      #align-musicicon-songwriter {
        display: flex;
        align-items: center;
      }

      #left-side-container-texts {
        display: flex;
        flex-direction: column;
      }
    }

    #steps-section {
      .steps {
        .steps-col {
          display: flex;
          flex-direction: column;

          .step {
            margin: 20px 50px;
          }
        }
      }
    }

    #help-section {
      height: fit-content;
      margin-top: -34px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 60px 0;

      .cards {
        height: 250px;
        width: fit-content;
        border-radius: 6px;
        padding: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 20px 0;
      }
    }

    #footer {
      height: 90px;
      padding: 24px 0;

      #info {
        img {
          width: 140px;
          margin-bottom: 0;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    #first-section {
      background: #262626;

      #header-mp {
        padding: 10px 0;

        #align-space {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        #social-networks-container {
          margin-top: 20px;
          color: #8f8f8f;
        }
      }

      #loose-button {
        width: 90%;
      }

      #fs-content {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0 0 50px 0;

        #left-side-container {
          display: flex;
          flex-direction: column;
          margin-right: 0;
          align-items: center;
          justify-content: center;

          #align-musicicon-songwriter {
            margin-top: 30px;
            display: flex;
            align-items: center;
            justify-content: center;

            #composer-header {
              font-family: Inter;
              font-style: normal;
              font-weight: 600;
              font-size: 24px;
              line-height: 24px;
              letter-spacing: 0.25px;
              color: #e4e5e6;
            }

            #song-icon {
              margin-top: 0;
            }
          }

          #left-side-container-texts {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-left: 0;

            #composer-subheader {
              font-family: Inter;
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 16px;
              text-align: center;
              letter-spacing: 0.15px;
              margin-top: 15px;
              color: #ffffff;
            }

            #composer-text {
              width: initial;
              font-family: Inter;
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 19px;
              text-align: center;
              letter-spacing: 0.15px;
              color: #e4e5e6;
              padding: 0 15px;
            }
          }
        }

        #bg-logo {
          height: 90px;
          width: 100%;
          background: #202020;
          display: flex;
          justify-content: center;
          align-items: center;

          #logo {
            width: 212px;
          }
        }

        #song-icon {
          width: 32px;
          margin-top: 40px;
        }

        #composer-header {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 32px;
          line-height: 32px;
          text-align: center;
          color: #e4e5e6;
          margin: 8px 0;
        }

        #composer-subheader {
          width: 230px;
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          text-align: center;
          letter-spacing: 0.15px;
          text-align: center;
          color: #acacac;
          margin-top: 0;
          margin-bottom: 24px;
        }

        #composer-text {
          width: 177px;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 20px;
          text-align: center;
          color: #e4e5e6;
        }

        iframe {
          margin-top: 40px;
          margin-bottom: 20px;
          width: 100% !important;
          height: 315px !important;
          border: none !important;
        }
      }
    }

    #steps-section {
      padding: 0;
      margin-top: -2px;
      padding-bottom: 90px;

      .steps {
        flex-direction: column;

        .steps-col {
          flex-direction: column;
          align-items: center;

          .step {
            width: 80%;
            margin: 20px 50px;

            .number {
              font-weight: 600;
              font-size: 20px;
              line-height: 20px;
              margin-right: 8px;
            }

            .step-content {
              .step-title {
                font-family: Inter;
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 20px;
              }

              .step-description {
                font-family: Inter;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: 0.5px;
                margin-top: 8px;
              }
            }
          }
        }
      }
    }

    #loose-button {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 60px;
    }

    #help-section {
      width: 100%;
      height: auto;
      background: #2e2e2e;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0px 0 0 0;

      .cards {
        /* height: fit-content; */
        width: calc(100% - 48px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 56px 24px;
        margin: 0;
        border-radius: 0px;

        img {
          width: 26px;
          height: 26px;
          margin-bottom: 10px;
        }

        .title {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 20px;
          margin-bottom: 8px;
        }

        .subtitle {
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          text-align: center;
          letter-spacing: 0.15px;
          margin-bottom: 20px;
          width: 100%;
        }
      }
      .negative {
        background: transparent;
      }
    }

    #footer {
      height: 90px;
      background: #ffffff;
      padding: 24px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: -10px;

      #info {
        img {
          width: 110px;
          margin-bottom: 0;
        }
      }
    }
  }
`;

export const PrimaryButton = styled.div`
  width: 561px;
  height: 66px;
  background: #0d65ff;
  border: 1.5px solid #0d65ff;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  span {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;

    text-align: center;

    color: #ffffff;
  }

  @media screen and (max-width: 767px) {
    height: 52px;
    width: 90%;

    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
    }
  }
`;

export const SecondaryButton = styled.div`
  width: 456px;
  height: 66px;
  background: #ef233c;
  border: 1.5px solid #ef233c;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  span {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #ffffff;
  }

  @media screen and (max-width: 767px) {
    height: 52px;
    width: 100%;

    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
    }
  }
`;

export const SecondaryOutlineButton = styled.div`
  width: 456px;
  height: 66px;
  border: 1.5px solid #ef233c;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  span {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #ef233c;
  }

  @media screen and (max-width: 767px) {
    height: 52px;
    width: 100%;

    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
    }
  }
`;
